<template>
  <div
    v-if="dataLoad"
    class=" text-center my-2"
  >
    <b-card>
      <b-spinner
        type="grow"
        label="Loading..."
      />
    </b-card>

  </div>
  <b-card v-else>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name (En)"
            rules="required"
          >
            <b-form-group
              label="Name (En)"
              label-for="Name"
            >
              <b-form-input
                id="Name"
                v-model="tierForm.name_en"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Name (En)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label=" Name (Ar)"
              label-for="name"
            >
              <b-form-input
                id="Name"
                v-model="tierForm.name_ar"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Name (Ar)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Range Start -->
          <validation-provider
            #default="validationContext"
            name="Range Start"
            rules="required"
          >
            <b-form-group
              label="Range Start"
              label-for="Range Start"
            >
              <b-form-input
                id="Range Start"
                v-model="tierForm.range_start"

                :state="getValidationState(validationContext)"
                trim
                type="number"
                placeholder="Range Start"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Range End -->
          <validation-provider
            #default="validationContext"
            name="Range End"
            rules="required"
          >
            <b-form-group
              label="Range End"
              label-for="Range End"
            >
              <b-form-input
                id="Range End"
                v-model="tierForm.range_end"

                :state="getValidationState(validationContext)"
                trim
                type="number"
                placeholder="Range End"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-col
        v-if="errorMsg"
        cols="12"
      >
        <p class="text-danger">
          {{ errorMsg }}
        </p>
      </b-col>
      <!-- Server Errors -->
      <b-col
        v-if="Object.values(errors).length > 0"
        cols="12"
      >
        <p
          v-for="(error, key, index) in errors"
          :key="index"
          class="text-danger small"
        >
          {{ error.toString() }}
        </p>
      </b-col>

      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >  <b-button
             v-if="!loader && $route.name=='edit-campaign-tier'"
             variant="primary"
             class="mr-1"
             :disabled="invalid || Object.values(errors).length > 0 "
             @click="addSection()"
           >
             Save Changes
           </b-button>
          <b-button
            v-if="!loader && $route.name=='add-campaign-tier'"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addSection()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'

export default {

  data() {
    return {
      id: this.$route.params.id,
      isVoucher: false,
      required,
      allSelected: [],
      indeterminate: false,
      countries: [],
      users: [],
      loader: false,
      loaderStep: false,
      validationErrors: {},
      errorMsg: '',
      errors: {},
      file: '',
      dataLoad: false,
      stepsCount: 1,
      linksCount: 1,
      videosCount: 1,
      stepEdit:
        {
          title_en: '',
          title_ar: '',
          description_ar: '',
          description_en: '',

        },

      steps: [
        {
          title_en: '',
          title_ar: '',
          description_ar: '',
          description_en: '',

        },
      ],
      links: [{
        link_name_en: '',
        link_name_ar: '',
        attached_link: '',

      }],
      videos: [
        {
          url: '',

        },
      ],
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const tierForm = ref({

    })
    return {
      getValidationState,
      tierForm,
    }
  },
  computed: {
    breadcrumb() {
      return this.$route.meta.breadcrumb(this.$route)
    },
  },
  mounted() {
    this.showType()
  },
  methods: {
    getId(video) {
      return this.$youtube.getIdFromUrl(video.url)
    },
    selectFile() {
      this.$refs.refInputEl.$el.click()
    },
    previewVideo() {
      const video = document.getElementById('video-preview')
      const reader = new FileReader()
      reader.readAsDataURL(this.file)
      reader.addEventListener('load', () => {
        video.src = reader.result
      })
    },
    closeModal() {
      this.$refs.editStep.hide()
    },
    addStep() {
      this.steps.push({
        title_en: '',
        title_ar: '',
        description_ar: '',
        description_en: '',

      })
      this.stepsCount += 1
    },
    addLink() {
      this.Links.push({
        link_name_en: '',
        link_name_ar: '',
        attached_link: '',

      })
      this.linksCount += 1
    },
    addVideo() {
      this.videos.push({
        url: '',
      })
      this.videosCount += 1
    },

    changeProfile(e) {
      // eslint-disable-next-line prefer-destructuring
      // this.images = e.target.files[0]

      const input = e.target
      if (input.files) {
        for (let i = 0; i < input.files.length; i += 1) {
          const url = URL.createObjectURL(input.files[i])
          const img = new Image()
          img.src = window.URL.createObjectURL(input.files[i])
          img.onload = () => {
            this.tierForm.images.push({ url })
            this.images.push(input.files[i])
          }
        }

        // eslint-disable-next-line prefer-destructuring
      }
    },

    openStepDialog(step) {
      this.stepEdit = step
      this.$refs.editStep.show()
    },
    showType() {
      this.dataLoad = true

      if (this.$route.query.id) {
        axios.get(`admin/tiers/${this.$route.query.id}`).then(res => {
          this.tierForm = res.data.data.tier
        }).catch(() => {
          // this.formData = new FormData()
        }).finally(() => {
          this.dataLoad = false
        })
      } else {
        this.dataLoad = false

        return false
      }
      return true
    },

    addSection() {
      if (this.$route.name === 'edit-campaign-tier') {
        this.loader = true
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.tierForm) {
          formData.append(key, this.tierForm[key])
        }
        formData.delete('created_at')
        formData.delete('id')

        formData.append('product_gift_id', this.$route.params.id)

        axios.post(`admin/tiers/${this.$route.query.id}`, formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'campaign-tiers', params: { id: this.$route.params.id } })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ooops!!! An Unexpected Internal server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          } else if (error.response.status === 422) {
            if (error.response.data.errors !== undefined) {
              this.errorMsg = error.response.data.errors
            } else {
              this.errorMsg = error.response.data.message
            }
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.tierForm) {
          formData.append(key, this.tierForm[key])
        }

        formData.append('product_gift_id', this.$route.params.id)

        axios.post('admin/tiers', formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'campaign-tiers', id: this.$route.params.id })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ooops!!! An Unexpected Internal server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          } else if (error.response.status === 422) {
            if (error.response.data.errors !== undefined) {
              this.errorMsg = error.response.data.errors
            } else {
              this.errorMsg = error.response.data.message
            }
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },
  },

}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .deleteImgIcon{
    cursor: pointer;
    color: grey;
  }
  video{width: 200px;
  margin: 0px 10px}

  </style>
